.input_otp{
    /* background:blue; */
    font-size: 16px;
    color: #333;
    width: 48px !important;
    height: 48px !important;
    border: 1px solid #DBDBDB;
    /* padding: 20px; */
}
.container_otp{
    /* background:red; */
    /* font-size: 15px; */
    color: white;
    width: 48px;
    height: 48px;
    /* padding: 20px; */



}