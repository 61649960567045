.actived {
    cursor: pointer;
    font-family: "poppins-medium";
    opacity: 1;
    color: #161616;
}
.normal {
    cursor: pointer;
    font-family: "poppins-medium";
    color: #464646;
    opacity: 0.5;
    
}
.normal:hover {
    color: #161616;
    opacity: 1;
}

.icon-actived{
    opacity: 1;

}
.normal-icon{
    cursor: pointer;
    width: 24px;
    height: 24px;
    opacity: 0.5;
}

.normal-icon:hover {
    opacity: 1;
}
/* .my-svg:hover{
    fill: #106F39 !important;
    stroke: #106F39 !important;
} */

.icon:hover path {
    /* fill: red; */
    /* stroke:green;  */
}