* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: transparent;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

img {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

*::-moz-selection {
}

*::selection {
}

/* *::-webkit-scrollbar {
	width: 0.3rem;
}
*::-webkit-scrollbar-thumb:hover {
	background: #b7b7b7;
} */
ol,
ul {
    list-style: none;
}

blockquote {
    quotes: none;
}

blockquote:before,
blockquote:after {
    content: "";
    content: none;
}

del {
    text-decoration: line-through;
}

body {
    font-size: 1.0625rem;
    color: #333;
    background: #fff;
    overflow-x: hidden;
    min-width: 20rem;
    position: relative;
    font-family: "poppinsregular";
    /* text-transform: capitalize; */
}

input,
textarea,
select {
    font-family: "poppinsregular";
    background: unset;
    border: unset;
}

textarea,
input[type="number"]::-webkit-inner-spin-button,
input[type="text"],
input[type="button"],
input[type="submit"] {
    -webkit-appearance: none;
    border-radius: 4px;
}

*:focus {
    outline: none;
}

a {
    color: #333;
}

.submit:hover {
    filter: alpha(opacity=85);
    -moz-opacity: 0.85;
    -khtml-opacity: 0.85;
    opacity: 0.85;
}

.thumb {
    display: block;
    width: 100%;
}

.thumb img {
    display: block;
    width: 100%;
}

p {
    line-height: 1.6em;
    font-size: 1rem;
    color: #fff;
}

br.clear {
    clear: both;
    line-height: 0;
}

.slick-arrow {
    display: none !important;
}

@font-face {
    font-family: "poppins-semibold";
    src: url("../fonts/Poppins-SemiBold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "poppins-regular";
    src: url("../fonts/Poppins-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "poppins-medium";
    src: url("../fonts/Poppins-Medium.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

/* primary button */
.nill {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}
/* & .css-nnid7-Muislider- valueLabel .Muislider-valueLabelOpen {
    opacity: 0 !important;
}
& .css-nnid7-Muislider-valueLabel .Muislider-valueLabelOpen {
    opacity: 0;
}
&.css-nnid7-MuiSlider-valueLabel {
    opacity: 0;
}
&.MuiSlider-valueLabel {
    opacity: 0;
} */

.wrapper {
    width: 90%;
    max-width: 1350px;
    margin: 0 auto;
}

.direction {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 150px;
}
@media (max-width: 1080px) {
    .logbtn {
        display: none;
    }
}

.sliderslik {
    /* display: flex; */
}

@media (max-width: 1280px) {
    .direction {
        padding: 90px;
    }
}
@media (max-width: 980px) {
    .direction {
        padding: 40px;
        width: 70%;
    }
}
@media (max-width: 480px) {
    .direction {
        padding: 35px;
        width: 100%;
    }
}
* {
    ::-webkit-scrollbar {
        display: none;
    }
}
body.modal-open {
    overflow: hidden;
}
